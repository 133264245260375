// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-demo-homeoffice-jsx": () => import("./../../../src/pages/demo-homeoffice.jsx" /* webpackChunkName: "component---src-pages-demo-homeoffice-jsx" */),
  "component---src-pages-demo-jsx": () => import("./../../../src/pages/demo.jsx" /* webpackChunkName: "component---src-pages-demo-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-search-jsx": () => import("./../../../src/pages/search.jsx" /* webpackChunkName: "component---src-pages-search-jsx" */),
  "component---src-pages-storyblok-preview-js": () => import("./../../../src/pages/storyblok-preview.js" /* webpackChunkName: "component---src-pages-storyblok-preview-js" */),
  "component---themes-gatsby-theme-kn-src-templates-storyblok-collection-template-js": () => import("./../../../../../themes/gatsby-theme-kn/src/templates/StoryblokCollectionTemplate.js" /* webpackChunkName: "component---themes-gatsby-theme-kn-src-templates-storyblok-collection-template-js" */),
  "component---themes-gatsby-theme-kn-src-templates-storyblok-default-template-js": () => import("./../../../../../themes/gatsby-theme-kn/src/templates/StoryblokDefaultTemplate.js" /* webpackChunkName: "component---themes-gatsby-theme-kn-src-templates-storyblok-default-template-js" */)
}

